<template lang="">
<div>
    <v-container class="pa-2 d-flex justify-center">
        <v-col lg="4" sm="6" style="padding: 0px;">
            <v-row class="d-flex justify-center align-center">
                <v-img src="@/assets/img/checkmong/simple_azq/logo-Eisai_03.png" contain max-height="100px" max-width="150px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/02-Eisai-logo1.png" contain max-height="50px" max-width="70px"></v-img>
                <v-img src="@/assets/img/checkmong/simple_azq/logo3.png" contain max-height="50px" max-width="70px"></v-img>
            </v-row>
            <v-container class="">
                <br>
                <br>

                <v-row class="d-flex justify-center pt-10 pb-10">
                    <v-btn class="mb-7" elevation="0" outlined x-large block rounded color="#AE1B77" @click="goBrainGeneral">
                        <h2 style="font-weight: bold; letter-spacing: -1px; ">เรื่องทั่วไป</h2>
                    </v-btn>
                    <v-btn class="mb-7" elevation="0" outlined x-large block rounded color="#AE1B77" @click="goBrainActivity">
                        <h2 style="font-weight: bold; letter-spacing: -1px; ">กิจกรรมพัฒนาสมอง</h2>
                    </v-btn>
                    <v-btn class="mb-7" elevation="0" outlined x-large block rounded color="#AE1B77" @click="goCogmate">
                        <h3 style="font-weight: bold; letter-spacing: -1px; ">เครื่องมือวัดและติดตามสรรถภาพสมอง</h3>
                    </v-btn>
                    <v-btn class="mb-7" elevation="0" outlined x-large block rounded color="#AE1B77" @click="goHhc">
                        <h2 style="font-weight: bold; letter-spacing: -1px; text-transform: none !important; ">Hhc Thailand</h2>
                    </v-btn>
                </v-row>
                <br>

            </v-container>

        </v-col>
    </v-container>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    data() {
        return {
            logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            name: '',
            age: null,
            phone: '',
            valid: true,
            is_download_btn_active: false,
        };
    },
    watch: {

    },

    methods: {
        goBrainGeneral() {
            this.$router.push('/dementia_knowledge_general')
        },
        goBrainActivity() {
            this.$router.push('/dementia_knowledge_activity')
        },
        goCogmate() {
            window.open("https://cogmatethailand.com/get-started#special-package")
        },
        goHhc() {
            window.open("https://hhcthailand.com/category/health/brain/")
        }
    },
    beforeCreate() {

    },
    mounted() {

    },
    created() {},
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

<style scoped>
.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-pink {
    background: #AE1B77 !important;
    color: white !important;
    box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
    font-weight: bold !important;
}

.text-pink {
    color: #601550 !important;
}

.text-size {
    font-size: 18px;
}
</style>
